import React, { useState } from "react"
import styles from "./winter-thanks.module.scss"
import logo from "../../images/Logo Page.png"
import face from "../../images/Facebook-white.png"
import insta from "../../images/Instagram-white.png"
import Menu from "../Menu/Menu"
import ButtonMenu from "../ButtonMenu/ButtonMenu"
import { Link } from "gatsby"

const Thankspage = () => {
  const [toggleMenu, setToggleMenu] = useState(true)

  const toggle = () => {
    if (toggleMenu) {
      setToggleMenu(false)
    } else {
      setToggleMenu(true)
    }
  }

  return (
    <>
      <Menu activeMenu={toggleMenu} />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.exploracontainer}>
            {/* <Link to="/viajesconconexion/experiencias" ><p className={styles.exploratext}>EXPLORÁ</p></Link> */}
          </div>
          <div className={styles.centerLogo}>
            <img src={logo} alt="" className={styles.logo} />
          </div>
          <ButtonMenu activeMenu={toggleMenu} toggleMenu={toggle} />
        </div>
        <div className={styles.contentWithoutHeader}>
          <div className={styles.bodyinicio}>
            <h1 className={styles.textsubtitle}>
              {/* Ya estás participando, */}
              ¡Listo!
              </h1>
            <h1 className={styles.texttitle}>
              {/* ¡Mucha suerte! */}
              Muy pronto vas a recibir noticias de Corona.
              </h1>
          </div>
          <div className={styles.redes}>
            <Link to="https://www.facebook.com/cervezacorona">
              <img src={face} alt="" className={"mr-3 " + styles.iconredes} />
            </Link>
            <Link to="https://instagram.com/corona_argentina?igshid=oxhoy01vemn0">
              <img src={insta} alt="" className={"mr-3 " + styles.iconredes} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Thankspage
