import React from "react"
import WinterThanks from "../../components/Winter/WinterThanks"
import SEO from "../../components/seo"
import "../../styles/layout.module.css"
const Thanks = () => {
  return (
    <div>
      <SEO title="Gracias" />
      <WinterThanks />
    </div>
  )
}

export default Thanks
